import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FileService } from "web-frontend-component-library/services/file";
import { environment } from "@env/environment";
import { IGridRequestPayload, IPagedResponse } from "web-frontend-component-library/grid/interfaces";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
import { ISelectItem } from "web-frontend-component-library/interfaces";
import { ILogFileInfo } from "@core/interfaces/log-file-info";
import { IDownloadsJournalProgressResponse } from "@app/core/interfaces/downloads-journal";

@Injectable({
  providedIn: 'root',
})
export class ProfileDownloadsJournalApiService {
  constructor(
    private httpClient: HttpClient,
    private fileService: FileService,
  ) {
  }

  /**
   * Скачать файл
   * @param id файла
   * @param fileName Имя файла
   */
  public getDownloadFile(id: number, fileName: string) {
    this.fileService.downloadFile(
      `${environment.REPORT_URL}/profile/report/log/${id}/download/stream`,
      fileName,
    );
  }

  /**
   * Получить грид выгрузок
   * @param rp параметры запроса
   */
  public getDownloadGrid(rp: IGridRequestPayload): Observable<IPagedResponse> {
    return this.httpClient
      .post<IPagedResponse>(`${environment.REPORT_URL}/profile/report/log/grid`, rp)
      .pipe(share());
  }

  // Справочник "Состояния выгрузки" (список)
  public getDownloadStateList(
    codes: string[] = ['UPLOAD_LOG'],
    isShortName: boolean = false,
  ): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(
        `${environment.REPORT_URL}/report/log/dict/state/list`,
        { codes: codes, isShortName: isShortName },
      )
      .pipe(share());
  }

  // Справочник "Тип выгрузки" (список)
  public getDownloadTypeList(): Observable<ISelectItem[]> {
    return this.httpClient
      .post<ISelectItem[]>(
        `${environment.API_URL}/dictionary/drop-down/upload-type`,
        {},
      )
      .pipe(share());
  }
  /**
   * Получение состояния выполнения процесса
   * @param uuid - uuid процесса
   */
  public getProgress(uuid: string): Observable<IDownloadsJournalProgressResponse> {
    return this.httpClient.get<IDownloadsJournalProgressResponse>
      (
        `${environment.API_URL}/system/progress`,
        {
          params: { uuid },
        }
      ).pipe(share());
  }
  /**
   * Получение ID журнала выгрузки по UUID индикатора прогресса.
   * @param progressIndicatorUuid - uuid процесса
   */
  public getLogIdByProccessUuid(progressIndicatorUuid: string): Observable<number> {
    return this.httpClient.get<number>(
      `${environment.REPORT_URL}/profile/report/log/${progressIndicatorUuid}/report/log/id`,
    );
  }
  /**
   * Получение информации о файлах журнала выгрузки по их UUID индикатора прогрессов.
   * @param progressIndicatorUuids - список uuid процессов
   */
  public getLogFilesInfo(progressIndicatorUuids: string[]): Observable<ILogFileInfo[]> {
    return this.httpClient.post<ILogFileInfo[]>(`${environment.REPORT_URL}/profile/report/log/files/info`, progressIndicatorUuids);
  }
}
