import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import {
  IGridExportParams,
  IPagedResponse,
  IUniversalGridRequest,
  IUniversalGuideInfo,
  IUniversalGuideUpdateRequest
} from 'web-frontend-component-library/grid/interfaces';
import { UniversalGridApiService } from 'web-frontend-component-library/grid/services/universal-grid-api';
import { FileService } from 'web-frontend-component-library/services/file';

@Injectable({
  providedIn: 'root',
})
export class AsuerUniversalGridApiService implements UniversalGridApiService {
  constructor(
    private httpClient: HttpClient,
    private fileService: FileService,
  ) {}

  public getInfo(code: string): Observable<IUniversalGuideInfo> {
    return this.httpClient
      .get<IUniversalGuideInfo>(
        `${environment.API_URL}/admin/object/info?code=${code}`,
      )
      .pipe(share());
  }

  public getGrid(
    requestPayload: IUniversalGridRequest,
  ): Observable<IPagedResponse> {
    return this.httpClient
      .post<IPagedResponse>(
        `${environment.API_URL}/admin/object/grid`,
        requestPayload,
      )
      .pipe(share());
  }

  public getRow(code: string, id: number): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.API_URL}/admin/object/row?code=${code}&id=${id}`)
      .pipe(share());
  }

  public addRow(request: IUniversalGuideUpdateRequest): Observable<any> {
    return this.httpClient
      .post(`${environment.API_URL}/admin/object`, request)
      .pipe(share());
  }

  public updateRow(request: IUniversalGuideUpdateRequest): Observable<any> {
    return this.httpClient
      .put(`${environment.API_URL}/admin/object`, request)
      .pipe(share());
  }

  public deleteRow(code: string, id: number): Observable<any> {
    return this.httpClient
      .delete(`${environment.API_URL}/admin/object?code=${code}&id=${id}`)
      .pipe(share());
  }

  /**
   * Выгрузка грида в файл
   * @param body параметры запроса
   */
  public getGridReport(body: IGridExportParams) {
    this.fileService.downloadFile(
      `${environment.API_URL}/admin/object/grid/report/stream`,
      null,
      body,
      true,
    );
  }
}
