import { Injectable } from "@angular/core";
import { DateFormat } from "web-frontend-component-library/enums";
import * as dayjs from 'dayjs';
import { numberBetween } from "web-frontend-component-library/functions";

export interface IExpiryDateIcons {
  sixMonth: string;
  threeMonth: string;
  expired: string;
  sixMonthHover: string;
  threeMonthHover: string;
  expiredHover: string;
}

@Injectable()
export class ExpiryDateIconsHelperService {
  getExpiryDateIcon(date: string, dateFormat: DateFormat, icons: IExpiryDateIcons) {
    const currentMoment = dayjs().startOf('day');
    const expiryMoment = date ? dayjs(date, dateFormat) : null;
    if (expiryMoment) {
      const diff = currentMoment.diff(expiryMoment, 'days');
      if (numberBetween(diff, -180, -90)) {
        return icons.sixMonth;
      }
      if (numberBetween(diff, -89, -1)) {
        return icons.threeMonth;
      }
      if (diff >= 0) {
        return icons.expired;
      }
    }
    return null;
  };

  getExpiryDateIconHover(date: string, dateFormat: DateFormat, icons: IExpiryDateIcons) {
    const currentMoment = dayjs().startOf('day');
    const expiryMoment = date ? dayjs(date, dateFormat) : null;
    if (expiryMoment) {
      const diff = currentMoment.diff(expiryMoment, 'days');
      if (numberBetween(diff, -180, -90)) {
        return icons.sixMonthHover;
      }
      if (numberBetween(diff, -89, -1)) {
        return icons.threeMonthHover;
      }
      if (diff >= 0) {
        return icons.expiredHover;
      }
    }
    return null;
  }
}
