import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from "web-frontend-component-library/services/local-storage";
import { tap, catchError } from 'rxjs/operators';
import { STORAGE_KEYS } from '@app/app.enums';
import { ITokenResponse } from 'web-frontend-component-library/interfaces';

const GRANT_TYPE_PASSWORD: string = 'password';
const GRANT_TYPE_REFRESH_TOKEN: string = 'refresh_token';

@Injectable()
export class OAuth2Service
  extends AuthService {

  protected readonly tokensStorageKey: string = STORAGE_KEYS.AUTH;

  constructor(
    private httpClient: HttpClient,
    protected localStorageService: LocalStorageService,
  ) {
    super(localStorageService);
    this.tokenResponse$.next(this.getTokenResponseFromStorage());
  }

  public logout() {
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.getToken()}`,
    });
    return this.httpClient.post(
      `${environment.API_URL}/public/revoke`,
      {},
      { headers },
    ).pipe(tap(() => this.clearCredentials()));
  }

  public refreshToken(authUrl: string): Observable<ITokenResponse> {
    const formData = new FormData();
    formData.append('grant_type', GRANT_TYPE_REFRESH_TOKEN);
    formData.append('refresh_token', this.tokenResponse.refresh_token);
    return this.httpClient.post<ITokenResponse>(
      `${authUrl}/ws-auth/security/oauth/token`,
      formData,
    ).pipe(
      tap((res) => this.setCredentials(res)),
      catchError((error) => {
        this.clearCredentials();
        return throwError(error);
      }),
    );
  }

  public getAuthCodeUrl(url: string = null): string {
    const redirect_uri: string = `${document.location.origin}/ws-web-backend/public/auth`;
    const state: string = url && url !== '' ? btoa(url) : null;
    this.localStorageService.setObjectByName(STORAGE_KEYS.AUTH_STATE, state);
    this.saveRedirectUrl(url);
    return `${environment.API_URL}/public/authorize?response_type=code&state=${state}&redirect_uri=${redirect_uri}`;
  }

  public getTokenFromCode(code: string): Observable<ITokenResponse> {
    const redirect_uri: string = `${document.location.origin}/ws-web-backend/public/auth`;
    const formData = new FormData();
    formData.append('code', code);
    formData.append('redirect_uri', redirect_uri);
    return this.httpClient.post<ITokenResponse>(
      `${environment.API_URL}/public/token`,
      formData,
    ).pipe(
      tap((res) => this.setCredentials(res)),
      catchError((error) => {
        this.clearCredentials();
        return throwError(error);
      }),
    );
  }

  public getAppAuthorizationCode(): string {
    return btoa(`${environment.AUTH_USERNAME}:${environment.AUTH_PASSWORD}`);
  }

  private saveRedirectUrl(url: string) {
    const redirectTo = url ? url.replace(document.location.origin, '') : null;
    this.localStorageService.setObjectByName(
      STORAGE_KEYS.REDIRECT_TO,
      redirectTo && redirectTo !== '' && redirectTo !== '/' ? redirectTo : null,
    );
  }
}
