import { Injectable } from '@angular/core';
import { UserApiService } from '@core/services/api/user-api.service';
import { SystemParamsService } from '@core/services/system-params.service';
import { NsiUserGroupAccessDto } from '@generatedDto/models';
import {
  IAccessObjects,
  IAccessTree,
  IAccessRights
} from 'web-frontend-component-library/interfaces';
import { MenuService } from 'web-frontend-component-library/services/menu';
import { VisibilityService } from 'web-frontend-component-library/services/visibility';

@Injectable({
  providedIn: 'root',
})
export class AccessService {
  private _userAccessRights: NsiUserGroupAccessDto;

  constructor(
    private userApiService: UserApiService,
    private systemParamsService: SystemParamsService,
    private menuService: MenuService,
    private visibilityService: VisibilityService,
  ) {

  }

  public getUserAccessObjects(accessObjects: IAccessObjects) {
    if (accessObjects) {
      this.visibilityService.setAccessObjects([
        ...accessObjects.uiObjectsCodes,
        ...accessObjects.resourceTypeCodes,
      ]);
    } else {
      this.visibilityService.setAccessObjects([]);
    }
  }

  public getUserAccessTree(accessTree: IAccessTree) {
    if (accessTree) {
      this.menuService.setUserMenuWithPrepare(accessTree.uiObjects);
    } else {
      this.menuService.setUserMenu(null);
    }
  }

  public clearUserSession() {
    this.menuService.setUserMenu(null);
    this.systemParamsService.clearSystemParameters();
    this.visibilityService.clearAccessObjects();
  }

  public updateUserAccessRights(userId: number): void {
    this.userApiService
      .getProfileAccessRights()
      .subscribe((rights: IAccessRights) => {
        if (rights) {
          this.visibilityService.setAccessRights(rights);
        } else {
          this.visibilityService.setAccessRights(null);
        }
      });
  }
}
