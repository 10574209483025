import { NgxEchartsModule } from 'ngx-echarts';
import { AppRoutingModule } from './app.routing';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { ContextMenuModule } from 'ngx-contextmenu';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { ComponentLibraryModule } from 'web-frontend-component-library/module';
import { MENU_ICONS, MENU_OPENED_URLS, MENU_URLS, STORAGE_KEYS, USER_ACCESS } from './app.enums';
import { UniversalGridApiService } from 'web-frontend-component-library/grid/services/universal-grid-api';
import { UniversalGridUtilsService } from 'web-frontend-component-library/grid/services/universal-grid-utils';
import { AsuerUniversalGridApiService } from '@core/services/api/asuer-universal-grid-api.service';
import { AsuerUniversalGridUtilsService } from '@shared/services/asuer-universal-grid-utils.service';
import { LazyModuleLoaderModule } from 'web-frontend-component-library/components/lazy-module-loader';
import { colorschemeBlue } from 'web-frontend-component-library/colorscheme';
import { SystemParamsService } from '@core/services/system-params.service';
import { NoAccessConfig, NoAccessModule } from 'web-frontend-component-library/components/no-access';
import { SYSTEM_PARAMETER_NAME } from 'web-frontend-component-library/enums';
import { PageWrongConfig, PageWrongModule } from 'web-frontend-component-library/components/page-wrong';
import { PageNotFoundConfig, PageNotFoundModule } from 'web-frontend-component-library/components/page-not-found';
import { map } from 'rxjs/operators';
import * as echarts from 'echarts';
import { DynamicImportTranslationLoader } from '@core/services/dynamic-import-translation-loader.service';
import { LoadingChunkFailErrorHandler } from 'web-frontend-component-library/services/loading-chunk-fail-error-handler';

function noAccessFactory(systemParamsService: SystemParamsService): NoAccessConfig {
  return {
    supportPhone$: systemParamsService.getPublicSystemParams()
      .pipe(map(res => res?.[SYSTEM_PARAMETER_NAME.SUPPORT_PHONE] ?? '')),
    supportEmail$: systemParamsService.getPublicSystemParams()
      .pipe(map(res => res?.[SYSTEM_PARAMETER_NAME.SUPPORT_EMAIL] ?? ''))
  }
}
function pageWrongFactory(systemParamsService: SystemParamsService): PageWrongConfig {
  return {
    homeUrl$: systemParamsService.getPublicSystemParams()
      .pipe(map(res => res?.[SYSTEM_PARAMETER_NAME.AUTH_URL] ?? ''))
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ContextMenuModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: DynamicImportTranslationLoader
      }
    }),
    ComponentLibraryModule.forRoot({
      menuConfig: {
        menuOpenedStorageKey: STORAGE_KEYS.MENU_OPENED,
        userMenuStorageKey: STORAGE_KEYS.USER_MENU,
        menuUrls: MENU_URLS,
        menuIcons: MENU_ICONS,
        menuOpenedUrls: MENU_OPENED_URLS,
      },
      accessObjectsStorageKey: STORAGE_KEYS.ACCESS_OBJECTS,
      accessRightsStorageKey: STORAGE_KEYS.ACCESS_RIGHTS,
      userAccess: USER_ACCESS,
      colorscheme: colorschemeBlue
    }),
    AppRoutingModule,
    LazyModuleLoaderModule,
    NoAccessModule.forRoot({
      provide: NoAccessConfig,
      useFactory: noAccessFactory,
      deps: [SystemParamsService]
    }),
    PageWrongModule.forRoot({
      provide: PageWrongConfig,
      useFactory: pageWrongFactory,
      deps: [SystemParamsService]
    }),
    PageNotFoundModule.forRoot({
      provide: PageNotFoundConfig,
      useValue: <PageNotFoundConfig>{
        mainUrl: '/main'
      }
    }),
    NgxEchartsModule.forRoot({
      echarts: { init: echarts.init }
    })
  ],
  providers: [
    Title,
    { provide: UniversalGridApiService, useClass: AsuerUniversalGridApiService },
    { provide: UniversalGridUtilsService, useClass: AsuerUniversalGridUtilsService },
    {
      provide: ErrorHandler,
      useClass: LoadingChunkFailErrorHandler
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
