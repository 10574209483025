import { Component, Input, EventEmitter, Output } from '@angular/core';
import { USER_ACCESS } from '@app/app.enums';
import { ILogFileInfo } from '@app/core/interfaces/log-file-info';
import { DownloadsJournalApiService } from '@app/core/services/api/admin/downloads-journal-api.service';
import { VisibilityService } from 'web-frontend-component-library/services/visibility';

@Component({
  selector: 'app-process-progress-success-modal',
  templateUrl: './process-progress-success-modal.component.html',
  styleUrls: ['./process-progress-success-modal.component.scss'],
})
export class ProcessProgressSuccessModalComponent {
  @Input() files: ILogFileInfo[];
  @Input() uuids: string[];
  @Output() goToDownloadsJournal = new EventEmitter<void>();

  public readonly accesses = {
    canGoToDownloadsJournal: this.visibilityService.isVisible(USER_ACCESS['MEASURE_OBJECT.reports']),
  };

  constructor(
    private downloadsJournalApiService: DownloadsJournalApiService,
    private visibilityService: VisibilityService,
  ) {}

  public download(reportLogId: number, fileName: string): void {
    this.downloadsJournalApiService.getDownloadFile(reportLogId, fileName);
  }

  public goToDownloadsJournalHandler(): void {
    this.goToDownloadsJournal.emit();
  }
}
