import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AddressCardService } from '@shared/components/address-card/address-card.service';
import {
  ISelectItem,
  TDictionaryRequest
} from 'web-frontend-component-library/interfaces';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AddressApiService } from '@core/services/api/address/address-api.service';
import {
  ICreateAddressTreeElement,
  ICreateAddressTreeObject,
  IAddressObjectRich,
  IAddressObjectSearchResult,
  IAddressSearchParams
} from '@core/interfaces/address';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AddressSource } from '@app/app.enums';
import { debounceTime, map } from 'rxjs/operators';
import { NoWhitespaceValidator } from 'web-frontend-component-library/validators';
import { DateTimeService } from 'web-frontend-component-library/services/date-time';
import {DictionaryApiService} from "@core/services/api/dictionary-api.service";
import { DateFormat } from 'web-frontend-component-library/enums';

@Component({
  selector: 'app-address-object-card',
  templateUrl: './address-object-card.component.html',
  styleUrls: ['./address-object-card.component.scss']
})
export class AddressObjectCardComponent implements OnInit, OnDestroy {

  /**
   * адресный объект
  */
  @Input() object: ICreateAddressTreeObject = null;
  /**
   * дерево элементов
   */
  @Input() tree: ICreateAddressTreeElement[] = null;
  /**
   * режим - просмотр, редактирование, создание
   */
  @Input() regime: 'view' | 'edit' | 'add' = 'view';
  /**
   * Событие изменения адресного объекта
   */
  @Output() onObjectChange: EventEmitter<ICreateAddressTreeObject> = new EventEmitter<ICreateAddressTreeObject>();

  public form: FormGroup = new FormGroup({
    addInfo: new FormControl(null, [NoWhitespaceValidator(), Validators.maxLength(4000)]),
    addressBuildingState: new FormControl(null, []),
    addressBuildingType: new FormControl(null, []),
    addressEstateType: new FormControl(null, [Validators.required]),
    buildingNumber: new FormControl({ value: null, disabled: true }, [Validators.maxLength(50), NoWhitespaceValidator()]),
    endDate: new FormControl(null, []),
    housingNumber: new FormControl(null, [Validators.maxLength(50), NoWhitespaceValidator()]),
    kadastrNumber: new FormControl(null, [NoWhitespaceValidator()]),
    objectNumber: new FormControl({ value: null, disabled: true }, [Validators.maxLength(50), NoWhitespaceValidator()]),
    postalCode: new FormControl(null, [Validators.maxLength(6), NoWhitespaceValidator()]),
    startDate: new FormControl(null, [Validators.required])
  });

  public addressField: string = '';

  /**
   * Справочник вида владения (дом, гараж, ...)
   */
  public readonly estateTypeDictionaryReq$: TDictionaryRequest = (params) => this.dictionaryApiService.getAddressEstateTypeDictionary({
    ...params,
    onlyActual: true,
  });
  /**
   * Справочник состояния строения
   */
  public readonly buildingStateDictionaryReq$: TDictionaryRequest = (params) => this.dictionaryApiService.getAddressBuildingStateDictionary({
    ...params,
    onlyActual: true,
  });
  /**
   * Справочник вида строения
   */
  public readonly buildingTypeDictionaryReq$: TDictionaryRequest = (params) => this.dictionaryApiService.getAddressBuildingTypeDictionary({
    ...params,
    onlyActual: true,
  });

  public headers: string[] = []; // основной заголовок
  public dateFormat: string = DateFormat.DATE; // последовательность заголовков элементов

  public tabsItems: ISelectItem[] = [
    {
      key: 'FIAS',
      value: this.translateService.instant('ADMIN_NSI.ADDRESS_OBJECT.CARD.TABS.FIAS'),
      disabled: false
    },
    {
      key: 'MANUAL',
      value: this.translateService.instant('ADMIN_NSI.ADDRESS_OBJECT.CARD.TABS.MANUAL'),
      disabled: false
    }
  ];
  public activeTabKey: 'FIAS' | 'MANUAL' = 'FIAS';

  private saveManualChangesSubscription: Subscription;

  private readonly whitespaceError = this.translateService.instant('GENERAL.WHITESPACE_ERROR_FIELDS');
  private readonly requiredError = this.translateService.instant('GENERAL.REQUIRED_FIELDS');

  constructor(
    private addressApiService: AddressApiService,
    private translateService: TranslateService,
    private dateTimeService: DateTimeService,
    private addressCardService: AddressCardService,
    private toastr: ToastrService,
    private dictionaryApiService: DictionaryApiService,
  ) { }

  ngOnInit(): void {
    this.form.controls['startDate'].setValue(this.dateTimeService.getDateFromBackend(new Date().toJSON(), this.dateFormat));
    // по умолчанию форма отключена
    this.form.disable();
    // если в дереве есть вручную созданный элемент, объект можно добавить только вручную
    if (this.tree.some(item => item.localId == null && item.addressRegistryId == null)) {
      this.tabsItems[0].disabled = true;
      this.activeTabKey = 'MANUAL';
      this.enableForm();
    }
    // если элемент создан вручную
    if (this.object.addressManual) {
      this.activeTabKey = 'MANUAL';
      this.enableForm();
      this.form.patchValue(this.object.addressManual);
    }
    // если элемент выбран из справочника
    // richData в режиме edit заполнен и для MANUAL объектов
    // поэтому else if
    else if (this.object.richData) {
      this.form.patchValue(this.object.richData);
    }
    // отключаем форму в режиме просмотра в любом случае
    if (this.regime == 'view') {
      this.form.disable();
      this.addressField = this.object.name;
    }
    else {
      this.addressField = this.addressCardService.getAddressTitle(this.tree, this.object);
    }
    // включаем форму в режиме редактирования для MANUAL объектов
    if (this.regime == 'edit' && this.object.sourceType == AddressSource.MANUAL) {
      this.activeTabKey = 'MANUAL';
      this.enableForm();
    }
    this.getObjectTypesData();
    this.getObjectHeaders();

    this.saveManualChangesSubscription = this.form.valueChanges
      .pipe(
        debounceTime(1000)
      )
      .subscribe((res) => {
        if (this.form.valid && this.activeTabKey == 'MANUAL') {
          this.saveManualChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this.saveManualChangesSubscription.unsubscribe();
  }

  /**
   * Выбрать значение из справочника и вставить его в форму.
   * @param item
   * @param controlName
   */
  public dictionarySelectItem(item: ISelectItem, controlName: string) {
    this.form.controls[controlName].setValue(item);
    if (this.activeTabKey == 'MANUAL' && this.regime != 'view') {
      let control: AbstractControl = null;
      switch (controlName) {
        case 'addressBuildingType':
          control = this.form.controls['buildingNumber'];
          break;
        case 'addressEstateType':
          control = this.form.controls['objectNumber'];
          break;
      }
      if (control) {
        if (item) {
          control.enable();
          control.markAsTouched();
        }
        else {
          control.setValue(null);
          control.disable();
        }
      }
    }
  }

  /**
   * Сохранить в дерево введенный вручную объект
   */
  private saveManualChanges() {
    let newObject: ICreateAddressTreeObject = {
      ...this.object,
      addressManual: {
        addInfo: this.form.controls['addInfo'].value && this.form.controls['addInfo'].value !== ''
          ? this.form.controls['addInfo'].value : null,
        addressBuildingStateId: this.form.controls['addressBuildingState'].value ?
          this.form.controls['addressBuildingState'].value.key
          : null,
        addressBuildingTypeId: this.form.controls['addressBuildingType'].value ?
          this.form.controls['addressBuildingType'].value.key
          : null,
        addressEstateTypeId: this.form.controls['addressEstateType'].value ?
          this.form.controls['addressEstateType'].value.key
          : null,
        addressTreeId: null,
        buildingNumber: this.form.controls['buildingNumber'].value && this.form.controls['buildingNumber'].value !== ''
          ? this.form.controls['buildingNumber'].value : null,
        endDate: this.form.controls['endDate'].value && this.form.controls['endDate'].value !== ''
          ? this.form.controls['endDate'].value : null,
        housingNumber: this.form.controls['housingNumber'].value && this.form.controls['housingNumber'].value !== ''
          ? this.form.controls['housingNumber'].value : null,
        kadastrNumber: this.form.controls['kadastrNumber'].value && this.form.controls['kadastrNumber'].value !== ''
          ? this.form.controls['kadastrNumber'].value : null,
        objectNumber: this.form.controls['objectNumber'].value && this.form.controls['objectNumber'].value !== ''
          ? this.form.controls['objectNumber'].value : null,
        postalCode: this.form.controls['postalCode'].value && this.form.controls['postalCode'].value !== ''
          ? this.form.controls['postalCode'].value : null,
        startDate: this.form.controls['startDate'].value && this.form.controls['startDate'].value !== ''
          ? this.form.controls['startDate'].value : null
      },
      sourceType: AddressSource.MANUAL,
      objectTypeFullName: this.translateService.instant('ADDRESS_CARD.ADD_OBJECT'),

      addressBuildingState: this.form.controls['addressBuildingState'].value ?
        { ...this.form.controls['addressBuildingState'].value } : null,
      addressBuildingType: this.form.controls['addressBuildingType'].value ?
        { ...this.form.controls['addressBuildingType'].value } : null,
      addressEstateType: this.form.controls['addressEstateType'].value ?
        { ...this.form.controls['addressEstateType'].value } : null
    };
    if (this.regime == 'edit') {
      newObject.addressManual.id = this.object.localId;
      newObject.addressManual.addressTreeId = this.object.richData.addressTreeId;
      newObject.richData = this.object.richData;
    }
    newObject.name = this.addressCardService.getAddressTitle(this.tree, newObject);
    this.addressField = newObject.name;
    this.onObjectChange.next(newObject);
  }

  /**
   * Сменить вкладку. При смене вкладки чистим форму и возвращаем в дерево новый пустой объект.
   * @param key
   */
  public changeTab(key: 'FIAS' | 'MANUAL') {
    this.activeTabKey = key;
    this.resetForm();
    this.object.richData = null;
    this.returnDefaultObject();
    this.addressField = this.addressCardService.getAddressTitle(this.tree, this.object);
    if (key == 'FIAS') {
      this.form.disable();
    }
    else {
      this.enableForm();
    }
  }

  /**
   * Включить форму, не считая закрытых по умолчанию полей
   */
  private enableForm() {
    this.form.enable();
    this.form.controls['buildingNumber'].disable();
    this.form.controls['objectNumber'].disable();
  }

  private resetForm() {
    this.form.reset();
    this.form.controls['startDate'].setValue(this.dateTimeService.getDateFromBackend(new Date().toJSON(), this.dateFormat));
  }

  /**
   * Получить вид владений, вид строения и состояние строения
   */
  private getObjectTypesData() {
    let res = this.addressCardService.getObjectTypes(this.object);
    this.dictionarySelectItem(res[0], 'addressBuildingState');
    this.dictionarySelectItem(res[1], 'addressBuildingType');
    this.dictionarySelectItem(res[2], 'addressEstateType');
  }

  private getObjectHeaders() {
    this.headers = [];
    this.tree.forEach(el => {
      this.headers.push(`${el.objectTypeFullName ? el.objectTypeFullName : ''} ${el.name ? el.name : ''}`)
    });
  }

  /**
   * Результаты поиска элементов по подстроке
   */
  public searchResults: IAddressObjectSearchResult[] = [];

  /**
   * Поиск подходящих элементов по подстроке
   * @param value поисковое значение
   */
  public searchObjects(value: string) {
    let params: IAddressSearchParams = {
      addressRegistryId: this.tree[this.tree.length - 1].addressRegistryId,
      localId: this.tree[this.tree.length - 1].localId,
      rowCount: 20,
      search: value,
      systemGuid: this.tree[this.tree.length - 1].systemGuid
    };
    this.addressApiService.getObjectTreeSearch(params).subscribe(
      (res) => {
        this.searchResults = res;
      },
      (error) => {
        this.toastr.error(
          this.translateService.instant('ADDRESS_CARD.ERRORS.SEARCH_OBJECT'),
          this.translateService.instant('GENERAL.ERROR_LOAD'
          ));
      }
    );
  }

  /**
   * Выбрать объект из компонента поиска по подстроке
   * @param objData данные об объекте из поиска
   */
  public selectObject(objData: IAddressObjectSearchResult) {
    this.searchResults = [];
    let param = objData.localId ? objData.localId : objData.addressRegistryId;
    let req = objData.localId ? this.addressApiService.getObjectLocalInfo(param) : this.addressApiService.getObjectGlobalInfo(param);
    req
      .pipe(
        map(res => <IAddressObjectRich>this.addressCardService.prepareDatesForTemplate(res))
      )
      .subscribe(
        (res) => {
          this.object.richData = res;
          this.getObjectTypesData();
          this.saveSelectedObject(objData.addressRegistryId, res);
          this.form.patchValue(res);
          this.addressField = res.fullName;
        },
        (error) => {
          this.toastr.error(
            this.translateService.instant('ADDRESS_CARD.ERRORS.LOAD_OBJECT'),
            this.translateService.instant('GENERAL.ERROR_LOAD'
            ));
        }
      );
  }

  /**
   * Убрать выбранный из справочника объект
   */
  public removeSelectedObject() {
    this.object.richData = null;
    this.resetForm();
    this.returnDefaultObject();
  }

  /**
   * Сохранить в дерево выбранный из справочника объект
   * @param addressRegistryId
   */
  public saveSelectedObject(addressRegistryId: number = null, obj: IAddressObjectRich) {
    let newObj: ICreateAddressTreeObject = {
      addressManual: null,
      addressRegistryId,
      sourceType: obj.source,
      localId: obj.id,
      name: obj.fullName,
      objectTypeFullName: this.translateService.instant('ADDRESS_CARD.ADD_OBJECT'),
      richData: { ...obj }
    };
    this.onObjectChange.next(newObj);
  }

  /**
   * Вернуть в дерево новый пустой объект
   */
  private returnDefaultObject() {
    let newObj: ICreateAddressTreeObject = {
      addressManual: null,
      addressRegistryId: null,
      sourceType: null,
      localId: null,
      name: null,
      objectTypeFullName: this.translateService.instant('ADDRESS_CARD.TREE_NEW_OBJECT'),
      richData: null
    };
    this.onObjectChange.next(newObj);
  }

  /**
   * Проверить поля формы и вывести соотв. ошибку
   */
  public checkFields() {
    let required: boolean = false;
    let whitespace: boolean = false;
    Object.keys(this.form.controls).forEach(k => {
      required = !required ? this.form.controls[k].errors && this.form.controls[k].errors['required'] : true;
      whitespace = !whitespace ? this.form.controls[k].errors && this.form.controls[k].errors['whitespace'] : true;
    });
    if (required) {
      this.toastr.error(this.requiredError);
    }
    else if (whitespace) {
      this.toastr.error(this.whitespaceError)
    }
  }
}
