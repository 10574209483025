import {Component, Input} from '@angular/core';
import { ExpiryDateIconsHelperService, IExpiryDateIcons } from '@shared/services/expiry-date-icons-helper.service';
import * as dayjs from 'dayjs';
import { DateFormat } from 'web-frontend-component-library/enums';

@Component({
  selector: 'app-expire-date-text',
  templateUrl: './expire-date-text.component.html',
  styleUrls: ['./expire-date-text.component.scss'],
})
export class ExpireDateTextComponent {
  @Input() set value(value: string) {
    this.valueText = value;
    this.expiredDays = value ? this.getExpiredDays(value) : null;
    if (value && this.iconsForText) {
      this.icon = this.getExpiredIcon(value, this.iconsForText);
      this.iconHover = this.getExpiredIconHover(value, this.iconsForText);
      this.iconClass = this.icon;
    }
  }
  @Input() textOfType: string = null;
  @Input() set icons(value: IExpiryDateIcons){
    this.iconsForText = value;
    if (this.valueText && this.iconsForText) {
      this.icon = this.getExpiredIcon(this.valueText, this.iconsForText);
      this.iconHover = this.getExpiredIconHover(this.valueText, this.iconsForText);
      this.iconClass = this.icon;
    }
  }
  @Input() isVisibleIcon: boolean = true;

  public valueText: string = null;
  private iconsForText: IExpiryDateIcons = null;
  private icon: string = null;
  private iconHover: string = null;
  public expiredDays: number = null;
  public iconClass = null;

  constructor(private expiryDateIconsHelperService: ExpiryDateIconsHelperService) {}

  private getExpiredDays(value: string): number {
    const currentMoment = dayjs().startOf('day');
    const deviceNextCheckDate = value ? dayjs(value, DateFormat.BACKEND_ONLY_DATE) : null;
    return currentMoment.diff(deviceNextCheckDate, 'days');
  }

  private getExpiredIcon(value: string, icons: IExpiryDateIcons): string {
    return this.expiryDateIconsHelperService.getExpiryDateIcon(value, DateFormat.BACKEND_ONLY_DATE, icons);
  }

  private getExpiredIconHover(value: string, icons: IExpiryDateIcons): string {
    return this.expiryDateIconsHelperService.getExpiryDateIconHover(value, DateFormat.BACKEND_ONLY_DATE, icons);
  }

  public hoverIcon(value: boolean) {
    this.iconClass = value ? this.iconHover : this.icon;
  }
}
