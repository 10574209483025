import { ToastrService } from 'ngx-toastr';
import { AddressCardService } from '@shared/components/address-card/address-card.service';
import {
  ISelectItem,
  TDictionaryRequest
} from 'web-frontend-component-library/interfaces';
import { DateTimeService } from 'web-frontend-component-library/services/date-time';
import { Subscription } from 'rxjs';
import { AddressApiService } from '@core/services/api/address/address-api.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ICreateAddressTreeElement,
  IAddressElementRich,
  IAddressSearchParams,
  IAddressElementSearchResult
} from '@core/interfaces/address';
import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { AddressSource } from '@app/app.enums';
import { debounceTime, map } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NoWhitespaceValidator } from 'web-frontend-component-library/validators';
import {DictionaryApiService} from "@core/services/api/dictionary-api.service";
import { DateFormat } from 'web-frontend-component-library/enums';

@Component({
  selector: 'app-address-element-card',
  templateUrl: './address-element-card.component.html',
  styleUrls: ['./address-element-card.component.scss']
})
export class AddressElementCardComponent implements OnChanges, OnDestroy {

  /**
   * Выбранный элемент
   */
  @Input() element: ICreateAddressTreeElement = null;
  /**
   * Дерево элементов
   */
  @Input() tree: ICreateAddressTreeElement[] = null;
  /**
   * Режим - просмотр, редактирование, создание
   */
  @Input() regime: 'view' | 'edit' | 'add' = 'view';
  /**
   * Событие изменения выбранного элемента
   */
  @Output() OnElementChange: EventEmitter<ICreateAddressTreeElement> = new EventEmitter<ICreateAddressTreeElement>();

  public header: string; // основной заголовок
  public headerPaths: string[]; // последовательность заголовков из предыдущих элементов

  public dateFormat: string = DateFormat.DATE;

  public form: FormGroup = new FormGroup({
    endDate: new FormControl(null, []),
    isActual: new FormControl(true, []),
    liveStatus: new FormControl(true, []),
    name: new FormControl(null, [Validators.required, NoWhitespaceValidator()]),
    objectTypeFullName: new FormControl(null, [Validators.required]),
    objectTypeShortName: new FormControl(null, [Validators.required]),
    postalCode: new FormControl(null, [NoWhitespaceValidator()]),
    startDate: new FormControl(null, [Validators.required])
  });

  public tabsItems: ISelectItem[] = [
    {
      key: 'FIAS',
      value: this.translateService.instant('ADMIN_NSI.ADDRESS_OBJECT.CARD.TABS.FIAS'),
      disabled: false
    },
    {
      key: 'MANUAL',
      value: this.translateService.instant('ADMIN_NSI.ADDRESS_OBJECT.CARD.TABS.MANUAL'),
      disabled: false
    }
  ];
  public activeTabKey: 'FIAS' | 'MANUAL' = 'FIAS';

  /**
   * Справочник типов элемента
   */
  public readonly elementTypeDictionaryReq$: TDictionaryRequest = (params) => this.dictionaryApiService.getAddressObjectTypeDictionary({
    ...params,
    onlyActual: true
  })

  public elementTypeManual: boolean = false; // вручную или нет задается тип элемента

  private saveManualChangesSubscription: Subscription;

  private readonly whitespaceError = this.translateService.instant('GENERAL.WHITESPACE_ERROR_FIELDS');
  private readonly requiredError = this.translateService.instant('GENERAL.REQUIRED_FIELDS');

  constructor(
    private translateService: TranslateService,
    private addressApiService: AddressApiService,
    private dateTimeService: DateTimeService,
    private addressCardService: AddressCardService,
    private toastr: ToastrService,
    private dictionaryApiService: DictionaryApiService,
  ) { }

  ngOnDestroy(): void {
    if (this.saveManualChangesSubscription) {
      this.saveManualChangesSubscription.unsubscribe();
    }
  }

  ngOnChanges(): void {
    if (this.regime != 'view') {
      this.elementTypeManual = this.element.objectTypeManual ? this.element.objectTypeManual : false;
      this.resetForm();
      this.tabsItems[0].disabled = false;
      this.tabsItems[1].disabled = false;

      if (this.saveManualChangesSubscription) {
        this.saveManualChangesSubscription.unsubscribe();
      }

      if (this.element.addressTreeManual) {
        this.activeTabKey = 'MANUAL';
        this.fillFormFromManual(this.element);
      }
      // получаем подробные данные элемента
      else if (this.element.localId || this.element.addressRegistryId) {
        this.activeTabKey = 'FIAS';
      }

      // если в дереве есть вручную добавленные элементы, то следующий можно добавить только вручную
      if (this.tree.some(item => item.localId == null && item.addressRegistryId == null && item.position < this.element.position)) {
        this.tabsItems[0].disabled = true;
        this.activeTabKey = 'MANUAL';
      }
      else if (this.element.position === 0) {
        this.activeTabKey = 'FIAS';
      }

      this.saveManualChangesSubscription = this.form.statusChanges
        .pipe(
          debounceTime(1000),
        )
        .subscribe((res) => {
          if (this.activeTabKey == 'MANUAL' && this.form.valid) {
            this.saveManualChanges();
          }
        });
    }

    this.getElementHeaders();
  }

  /**
   * Сменить активную вкладку. При этом очистить данные выбранного элемента.
   * @param key ключ вкладки
   */
  public changeActiveTab(key: 'FIAS' | 'MANUAL') {
    this.activeTabKey = key;
    this.resetForm();
    this.element.richData = null;
    if (this.element.addressRegistryId || this.element.localId || this.element.addressTreeManual) {
      this.returnDefaultElement();
    }
  }

  /**
   * Выбор типа элемента из справочника
   */
  public changeElementType(item: ISelectItem) {
    if (item) {
      let fields = {
        objectTypeFullName: item.value,
        objectTypeShortName: item.ext.shortName
      };
      this.form.patchValue(fields);
    }
    else {
      this.form.controls['objectTypeFullName'].setValue(null);
      this.form.controls['objectTypeShortName'].setValue(null);
    }
  }

  /**
   * Убрать выбранный из списка тип элемента
   */
  public removeSelectedElementType() {
    this.form.controls['objectTypeFullName'].setValue('');
    this.form.controls['objectTypeShortName'].setValue('');
  }

  /**
   * Поменять способ ввода типа элемента (вручную или из справочника)
   */
  public changeElementTypeSelection() {
    this.elementTypeManual = !this.elementTypeManual;
    this.removeSelectedElementType();
  }

  /**
   * Результаты поиска элементов по подстроке
   */
  public searchResults: IAddressElementSearchResult[] = [];

  /**
   * Поиск подходящих элементов по подстроке
   * @param value поисковое значение
   */
  public searchElements(value: string) {
    let params: IAddressSearchParams = {
      localId: this.element.parentId,
      rowCount: 20,
      search: value,
      systemGuid: this.tree && this.tree[this.element.position - 1] && this.tree[this.element.position - 1].systemGuid
        ? this.tree[this.element.position - 1].systemGuid : null
    };
    this.addressApiService.getElementTreeSearch(params).subscribe(
      (res) => {
        this.searchResults = res;
      },
      (error) => {
        this.toastr.error(
          this.translateService.instant('ADDRESS_CARD.ERRORS.SEARCH_ELEMENT'),
          this.translateService.instant('GENERAL.ERROR_LOAD'
          ));
      }
    )
  }

  /**
   * Выбрать элемент из справочника
   * @param elData данные об элементе из поиска
   */
  public selectElement(elData: IAddressElementSearchResult) {
    this.searchResults = [];
    let isCAS = !elData.localId;
    let req = isCAS ?
      this.addressApiService.getElementGlobalInfo(elData.addressRegistryId)
      : this.addressApiService.getElementLocalInfo(elData.localId);
    req
      .pipe(
        map(res => <IAddressElementRich>this.addressCardService.prepareDatesForTemplate(res))
      )
      .subscribe(
        (res) => {
          this.element.richData = res;
          this.saveSelectedElement(isCAS, elData.systemGuid, res);
        },
        (error) => {
          this.toastr.error(
            this.translateService.instant('ADDRESS_CARD.ERRORS.LOAD_ELEMENT'),
            this.translateService.instant('GENERAL.ERROR_LOAD'
            ));
        }
      );
  }

  /**
   * Убрать выбранный из справочника элемент
   */
  public removeSelectedElement() {
    if (this.regime != 'add') {
      return;
    }
    this.returnDefaultElement();
  }

  /**
   * Сохранить выбраннй из справочника элемент в дерево
   */
  private saveSelectedElement(isCAS: boolean, systemGuid: string, el: IAddressElementRich) {
    let newElement: ICreateAddressTreeElement = {
      addressRegistryId: isCAS == true ? el.id : null,
      localId: isCAS == false ? el.id : null,
      position: this.element.position,
      sourceType: el.source,
      addressTreeManual: null,
      name: el.name,
      objectTypeFullName: el.objectTypeFullName,
      systemGuid,
      richData: el
    };
    this.OnElementChange.next(newElement);
  }

  /**
   * Сохранить введенный вручную элемент в дерево
   */
  private saveManualChanges() {
    if (this.activeTabKey == 'MANUAL' && this.form.valid) {
      let newElement: ICreateAddressTreeElement = {
        ...this.element,
        sourceType: AddressSource.MANUAL,
        addressTreeManual: {
          endDate: this.form.value.endDate && this.form.value.endDate !== '' ? this.form.value.endDate : null,
          isActual: this.form.value.isActual == true ? 1 : 0,
          liveStatus: this.form.value.liveStatus == true ? 1 : 0,
          name: this.form.value.name && this.form.value.name !== '' ? this.form.value.name : null,
          objectTypeFullName: this.form.value.objectTypeFullName && this.form.value.objectTypeFullName !== ''
            ? this.form.value.objectTypeFullName : null,
          objectTypeShortName: this.form.value.objectTypeShortName && this.form.value.objectTypeShortName !== ''
            ? this.form.value.objectTypeShortName : null,
          parentId: this.element.parentId,
          postalCode: this.form.value.postalCode && this.form.value.postalCode !== ''
            ? this.form.value.postalCode : null,
          startDate: this.form.value.startDate && this.form.value.startDate !== '' ? this.form.value.startDate : null
        },
        name: this.form.value.name && this.form.value.name !== '' ? this.form.value.name : null,
        objectTypeFullName: this.form.value.objectTypeFullName && this.form.value.objectTypeFullName !== ''
          ? this.form.value.objectTypeFullName : null,
        objectTypeManual: this.elementTypeManual,
        richData: null
      };
      if (this.regime == 'edit') {
        newElement.addressTreeManual.id = newElement.localId;
        newElement.richData = { ...this.element.richData };
      }
      this.OnElementChange.next(newElement);
    }
  }

  private returnDefaultElement() {
    let newElement: ICreateAddressTreeElement = {
      addressRegistryId: null,
      addressTreeManual: null,
      localId: null,
      position: this.element.position,
      sourceType: null,
      name: null,
      objectTypeFullName: this.element.position == 0
        ? this.translateService.instant('ADDRESS_CARD.REGION')
        : this.translateService.instant('ADDRESS_CARD.TREE_NEW_ELEMENT')
    };
    this.OnElementChange.next(newElement);
  }

  private resetForm() {
    this.form.reset();
    this.form.controls['startDate'].setValue(this.dateTimeService.getDateFromBackend(new Date().toJSON(), this.dateFormat));
    this.form.controls['isActual'].setValue(true);
    this.form.controls['liveStatus'].setValue(true);
  }

  private fillFormFromManual(el: ICreateAddressTreeElement) {
    this.form.reset();
    this.form.patchValue(el.addressTreeManual);
  }

  /**
   * Получить заголовки элемента
   */
  private getElementHeaders() {
    this.header = this.element.position === 0 ? this.translateService.instant('ADDRESS_CARD.REGION') :
      this.element.name ? `${this.element.objectTypeFullName} ${this.element.name}`
        : this.translateService.instant('ADDRESS_CARD.TREE_NEW_ELEMENT');

    this.headerPaths = [];
    this.tree.forEach(el => {
      if (el.position < this.element.position) {
        this.headerPaths.push(`${el.objectTypeFullName ? el.objectTypeFullName : ''} ${el.name ? el.name : ''}`)
      }
    });
  }

  /**
   * Проверить поля формы и вывести соотв. ошибку
   */
  public checkFields() {
    let required: boolean = false;
    let whitespace: boolean = false;
    Object.keys(this.form.controls).forEach(k => {
      required = !required ? this.form.controls[k].errors && this.form.controls[k].errors['required'] : true;
      whitespace = !whitespace ? this.form.controls[k].errors && this.form.controls[k].errors['whitespace'] : true;
    });
    if (required) {
      this.toastr.error(this.requiredError);
    }
    else if (whitespace) {
      this.toastr.error(this.whitespaceError)
    }
  }
}
